import React, { useState, useEffect } from "react";
import axios from "axios"; // For API calls
import LeftChevron from "../../assets/left-chevron.png";
import RightChevron from "../../assets/right-chevron.png";
// Removed FaStar import since we're using a text button now
import { useAuth } from "../../contexts/AuthContext"; // Get current user
import { Document, Packer, Paragraph, TextRun } from "docx"; // For Word export
import { saveAs } from "file-saver"; // For saving the file

export default function DescriptionClips({ formData }) {
  const { currentUser } = useAuth(); // Get current user from AuthContext
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const clipsPerPage = 3;
  const totalDots = Math.ceil(formData.clips.length / clipsPerPage);
  const [userId, setUserId] = useState(null); // To store user_id once fetched
  const backendUrl = "https://socialoha-server-0b454a3e2f86.herokuapp.com";
  console.log(formData);

  // Fetch the user ID based on the currentUser's email
  useEffect(() => {
    const fetchUserId = async () => {
      if (currentUser && currentUser.email) {
        try {
          const userResponse = await axios.get(`${backendUrl}/queries/data_user`);
          const users = userResponse.data;
          const user = users.find((item) => item.email === currentUser.email);

          if (user) {
            setUserId(user.user_id);
          } else {
            console.log("No user found with the provided email.");
          }
        } catch (error) {
          console.error("Error fetching user ID:", error);
        }
      }
    };

    fetchUserId();
  }, [currentUser]);

  // Check favorite status when user ID is available
  useEffect(() => {
    if (userId) {
      const fetchFavoriteStatus = async () => {
        try {
          const response = await axios.get(`${backendUrl}/queries/saved_reels/${userId}`);
          const savedReels = response.data;
          const isSaved = savedReels.some((reel) =>
            reel.clips.some(
              (clip) =>
                clip.description === formData.clips[currentIndex].description
            )
          );
          setIsFavorite(isSaved);
        } catch (error) {
          console.error("Error checking favorite status:", error);
        }
      };

      fetchFavoriteStatus();
    }
  }, [userId, formData.clips, currentIndex]);

  const nextSlide = () => {
    if (currentIndex < totalDots - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const exportToWord = () => {
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              text: "Reel Export",
              heading: "Heading1",
            }),
            ...formData.clips
              .map((clip, index) => [
                new Paragraph({
                  text: `Clip ${index + 1}`,
                  heading: "Heading2",
                }),
                new Paragraph({
                  text: `Start Time: ${clip.start_time} seconds`,
                }),
                new Paragraph({
                  text: `Description: ${clip.description}`,
                }),
                new Paragraph({
                  text: `Text Overlay: ${clip.text_overlay}`,
                }),
              ])
              .flat(),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "reel_export.docx");
    });
  };

  // Toggle favorite/save status
  const toggleFavorite = async () => {
    if (isFavorite) {
      // Unsave the clip
      try {
        await axios.delete(
          `${backendUrl}/queries/delete_saved_reel/${userId}/${formData.saved_reel_id}`
        );
        setIsFavorite(false);
      } catch (error) {
        console.error("Error unsaving the clip:", error);
      }
    } else {
      // Save the clip
      try {
        await axios.post(`${backendUrl}/queries/create_saved_reel/${userId}`, {
          title: formData.mainIdea || formData.title,
          summary: formData.subIdea || formData.summary,
          duration: formData.duration, // Assuming numeric duration
          clips: formData.clips,
        });
        setIsFavorite(true);
      } catch (error) {
        console.error("Error saving the clip:", error);
      }
    }
  };

  return (
    <div className="px-16 mt-16 relative">
      {/* Save/Unsave Button at the top-right */}
      <div className="absolute top-[-130px] right-20 mt-4">
        <button
          onClick={toggleFavorite}
          className={`text-white font-bold ${
            isFavorite ? "bg-yellow-400" : "bg-gray-300"
          } rounded px-4 py-2`}
        >
          {isFavorite ? "Unsave" : "Save"}
        </button>
      </div>

      {formData.clips.length > 0 ? (
        <div className="flex flex-col items-center">
          <div className="flex items-center">
            <button
              className={`absolute left-4 ${
                currentIndex === 0
                  ? "opacity-50 cursor-not-allowed"
                  : "opacity-100 cursor-pointer"
              }`}
              onClick={prevSlide}
              disabled={currentIndex === 0}
            >
              <img src={LeftChevron} alt="Left Chevron" className="w-8 h-8" />
            </button>

            <div className="flex flex-row space-x-8 overflow-hidden w-full">
              {formData.clips
                .slice(
                  currentIndex * clipsPerPage,
                  currentIndex * clipsPerPage + clipsPerPage
                )
                .map((clip, index) => (
                  <div
                    key={index}
                    className="flex flex-col space-y-4 p-4 border border-gray-300 rounded shadow-md w-96"
                  >
                    <div className="h-10 flex flex-row justify-between items-center">
                      <p className="font-bold">
                        Clip {currentIndex * clipsPerPage + index + 1}
                      </p>
                      <p className="text-sm text-gray-500">
                        {clip.start_time} - {clip.end_time} seconds
                      </p>
                    </div>
                    <div className="h-56">
                      <label className="font-semibold">Script</label>
                      <p className="mt-2 text-gray-500 text-md">
                        {clip.description}
                      </p>
                    </div>
                    <div>
                      <label className="font-semibold">Text Overlay</label>
                      <p className="mt-1 text-gray-500">{clip.text_overlay}</p>
                    </div>
                  </div>
                ))}
            </div>

            <button
              className={`absolute right-4 ${
                currentIndex >= formData.clips.length - clipsPerPage
                  ? "opacity-50 cursor-not-allowed"
                  : "opacity-100 cursor-pointer"
              }`}
              onClick={nextSlide}
              disabled={currentIndex >= formData.clips.length - clipsPerPage}
            >
              <img src={RightChevron} alt="Right Chevron" className="w-8 h-8" />
            </button>
          </div>

          <div className="flex mt-6 space-x-2">
            {Array.from({ length: totalDots }).map((_, dotIndex) => (
              <div
                key={dotIndex}
                className={`w-3 h-3 rounded-full ${
                  currentIndex === dotIndex ? "bg-teal" : "bg-gray-300"
                }`}
              ></div>
            ))}
          </div>
          {/*
          <button
            onClick={exportToWord}
            className="absolute bottom-[-275px] right-5 bg-green-600 text-white px-4 py-2 rounded hover:bg-green-500 transition-colors duration-500 ease-in-out"
          >
            Export
          </button>
          */}
        </div>
      ) : (
        <p>No clips available.</p>
      )}
    </div>
  );
}

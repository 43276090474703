import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import DescriptionClips from "../AIContent/description_clips"; // Import DescriptionClips for displaying scripts
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

// const backendUrl = "http://127.0.0.1:8080"; // Replace with your backend URL
const backendUrl = "https://socialoha-server-0b454a3e2f86.herokuapp.com";

const PostPopup = ({ post, onClose, onUpdate, navigate }) => {
  const [updatedPost, setUpdatedPost] = useState(post);
  const [hasScript, setHasScript] = useState(false);
  const [scriptData, setScriptData] = useState(null);
  const [showScript, setShowScript] = useState(false);

  useEffect(() => {
    const checkForScript = async () => {
      try {
        const response = await axios.get(`${backendUrl}/queries/get_saved_reels_by_post/${post.planned_post_id}`);
        if (response.data && response.data.length > 0) {
          setHasScript(true);
          setScriptData(response.data[0]);
        } else {
          setHasScript(false);
          setScriptData(null);
        }
      } catch (error) {
        console.error("Error checking for script:", error);
      }
    };

    if (post.planned_post_id) {
      checkForScript();
    }
  }, [post.planned_post_id]);

  const handleInputChange = (field, value) => {
    setUpdatedPost((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `${backendUrl}/queries/update_planned_post/${post.user_id}/${updatedPost.planned_post_id}`,
        updatedPost
      );
      console.log("Planned post updated:", response.data);
      onUpdate(updatedPost);
      onClose();
    } catch (error) {
      console.error("Error updating planned post:", error);
    }
  };

  const handleScriptClick = () => {
    if (hasScript) {
      setShowScript(true);
    } else {
      navigate("/aicontent", { state: { plannedPost: post } });
    }
  };

  // Convert the stored date (assumed to be in UTC) to New York time
  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    if (isNaN(date.getTime())) return "";
    
    // Helper to pad numbers with leading zeros
    const pad = (num) => num.toString().padStart(2, "0");
    
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-indexed
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-[60%] h-[60%] max-h-[95vh] p-12 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <FaTimes size={20} />
        </button>

        {showScript && scriptData ? (
          <div>
            <button
              onClick={() => setShowScript(false)}
              className="mb-4 text-blue-500 text-base px-3 py-2 border border-blue-500 rounded hover:bg-blue-500 hover:text-white transition duration-200"
            >
              Back to Preview
            </button>
            <DescriptionClips formData={scriptData} />
          </div>
        ) : (
          <>
            <h2 className="text-2xl font-bold mb-6 text-gray-800">Post Preview</h2>
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Category</label>
                <input
                  type="text"
                  value={updatedPost.post_type}
                  onChange={(e) => handleInputChange("post_type", e.target.value)}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Topic</label>
                <input
                  type="text"
                  value={updatedPost.post_description}
                  onChange={(e) => handleInputChange("post_description", e.target.value)}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Due Date</label>
                <input
                  type="datetime-local"
                  value={formatDateTime(updatedPost.scheduled_time)}
                  onChange={(e) => handleInputChange("scheduled_time", e.target.value)}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
/>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Status</label>
                <select
                  value={updatedPost.status}
                  onChange={(e) => handleInputChange("status", e.target.value)}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                >
                  <option value="Pending">Pending</option>
                  <option value="Scheduled">Scheduled</option>
                  <option value="Posted">Posted</option>
                </select>
              </div>
            </div>

            <div className="mt-8 flex justify-between items-center">
              <button
                onClick={handleSave}
                className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Save
              </button>
              <button
                onClick={handleScriptClick}
                className={`px-6 py-2 text-white rounded-md ${
                  hasScript ? "bg-green-500 hover:bg-green-600" : "bg-gray-500 hover:bg-sky-500 animate-pulse"
                }`}
              >
                {hasScript ? "View Script" : "Generate Script"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PostPopup;